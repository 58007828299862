<template>
    <b-modal v-model="visibleComputed" size="xxl" centered :title="'Send to your phone|Envoyer sur votre téléphone'|tr" body-class="f-14" @show="setScreen(`home`)">
        <template v-if="screen === 'home'">
            <div class="text-center mb-4">
                <b-img :src="require('@/assets/icons/wayfinding/send-to-phone.webp')" width="150"/>
                <h2 v-tr>Send to your phone|Envoyer sur votre téléphone</h2>
            </div>
            <b-row>
                <b-col class="text-center">
                    <div class="p1 mb-2">{{tr('Scan the QR Code|Numérisez le code QR')}}</div>
                    <qr-code-with-overlay class="mx-auto" :url="qrCodeUrl"/>
                    <div class="p3 my-3" v-tr>
                        Scan the QR code with your phone to receive step by step instructions|
                        Numérisez le code QR avec votre téléphone pour recevoir les instructions
                    </div>
                </b-col>
                <b-col>
                    <b-card class="gradient-card mt-5" @click="setScreen('sms')" no-body style="height: 250px;">
                        <b-card-body>
                            <b-img width="150" :src="require('@/assets/icons/sms-code.svg')"/>
                            <div class="ml-2 my-2 pl-2 text-left">
                                <div class="p1 mb-2 text-accent" v-tr>Send by SMS|Envoyer par texto</div>
                                <div v-tr>We won't store your Phone number|Nous n'enregistrerons pas votre numéro de téléphone</div>
                            </div>
                        </b-card-body>
                    </b-card>
                </b-col>
            </b-row>
            <hr>
            <div class="text-center">
                <h2 class="mt-4 mb-4" v-tr>How to scan QR Code?|Comment numériser un code QR ?</h2>
            </div>
            <b-row>
                <b-col>
                    <b-card class="gradient-card" @click="setScreen('iphone')" style="height: 250px;">
                        <i class="fab fa-apple fa-6x" style="color: #777;"/>
                        <div class="ml-2 my-2 pl-2 text-left">
                            <div class="p1 mb-2 text-accent" v-tr>With iPhone|Avec iPhone</div>
                            <div v-tr>If you have an Apple smartphone, click here to learn how to scan a QR Code|Si vous avez un téléphone Apple, cliquez ici pour apprendre comment numériser un code QR</div>
                        </div>
                    </b-card>
                </b-col>
                <b-col>
                    <b-card class="gradient-card" @click="setScreen('android')" no-body style="height: 250px;">
                        <b-card-body style="border-color: #4faf53">
                            <i class="fab fa-android fa-6x" style="color: #4faf53;"/>
                            <div class="ml-2 my-2 pl-2 text-left">
                                <div class="p1 mb-2 text-success" v-tr>With Android|Avec Android</div>
                                <div v-tr>If your smartphone is any other phone, click here to learn how to scan a QR Code|Si vous avez un autre téléphone, cliquez ici pour apprendre comment numériser un code QR</div>
                            </div>
                        </b-card-body>
                    </b-card>
                </b-col>
            </b-row>
        </template>

        <template v-if="screen === 'sms'">
            <b-btn @click="setScreen('home')" variant="info" size="lg" style="position: absolute; left: 10px; top: 10px;">
                <i class="fa-chevron-left mr-1" :class="fontAwesomePrefix"/>
                <span v-tr>Back|Retour</span>
            </b-btn>
            <h1 class="mb-3 text-center" v-tr>Send by texto|Envoyer par SMS</h1>

            <div class="text-center">
                <div class="p2 text-center">{{tr(`Please enter your phone number|Merci d'entrer votre numéro de téléphone`)}}</div>
                <b-input-group class="mt-2 mb-3 mx-auto" size="lg" style="width: 400px">
                    <b-select v-model="extension" style="max-width: 140px">
                        <option v-for="(phone, isoCode) in countryPhones" :value="phone">
                            {{ isoCode }} {{ phone }}
                        </option>
                    </b-select>
                    <b-input v-model="phone" size="lg" maxlength="10" autofocus placeholder="(___) ___-____"/>
                    <!-- Breaks virtual-keypad -->
<!--                    <b-input type="tel" :value="formatPhone(phone)" size="lg" autofocus placeholder="(___) ___-____"/>-->
                </b-input-group>

                <virtual-keypad :value="phone" @change="onKeyboardChange" :max-length="10"/>

                <b-btn @click="shareSMS" block class="mt-3 mx-auto" size="lg" variant="accent" :disabled="!isPhoneValid" style="width: 400px; border: 2px solid rgba(0, 0, 0, 0.3)">
                    <span v-tr>Send|Envoyer</span>
                    <i class="fas fa-paper-plane-top ml-1"/>
                </b-btn>

                <div class="mt-4 mb-3">{{tr(`We won't store your Phone number|Nous n'enregistrerons pas votre numéro de téléphone`)}}</div>
            </div>
        </template>

        <template v-if="screen === 'iphone'">
            <b-btn @click="setScreen('home')" variant="info" size="lg" style="position: absolute; left: 10px; top: 10px;">
                <i class="fa-chevron-left mr-1" :class="fontAwesomePrefix"/>
                <span v-tr>Back|Retour</span>
            </b-btn>
            <h1 class="mb-3 text-center" v-tr>With iPhone|Avec iPhone</h1>
            <b-row>
                <b-col cols="4" class="text-center">
                    <div class="d-flex align-items-center" style="height: 500px">
                        <b-img class="mx-auto" width="150" :src="require('@/assets/img/qr-codes/iphone-photo-icon.webp')"/>
                    </div>
                    <p class="p2 mt-2" v-tr>1. Open the Camera App|1. Ouvrez l'application Appareil Photo</p>
                </b-col>
                <b-col cols="4" class="text-center">
                    <b-img height="500" :src="require('@/assets/img/qr-codes/qr-scan-hand.webp')"/>

                    <p class="p2 mt-2" v-tr>2. Place the phone to make the code visible on the screen|2. Placez le téléphone de manière à ce que le code apparaisse à l’écran</p>
                </b-col>
                <b-col cols="4" class="text-center">
                    <qr-code-with-overlay class="mx-auto" height="500" center :url="qrCodeUrl" :overlay-url="require('@/assets/img/qr-codes/iphone-qrcode-overlay-tuto.webp')" />
                    <p class="p2 mt-2" v-tr>3. Tap the yellow popup that will appear below the code on the screen|3. Appuyez sur la notification jaune qui s'affiche sous le code à l'écran</p>
                </b-col>
            </b-row>
        </template>

        <template v-if="screen === 'android'">
            <b-btn @click="setScreen('home')" variant="info" size="lg" style="position: absolute; left: 10px; top: 10px;">
                <i class="fa-chevron-left mr-1" :class="fontAwesomePrefix"/>
                <span v-tr>Back|Retour</span>
            </b-btn>
            <h1 class="mb-3 text-center" v-tr>With Android|Avec Android</h1>
            <b-row>
                <b-col cols="4" class="text-center">
                    <div class="d-flex align-items-center" style="height: 180px">
                        <b-img class="mx-auto" width="150" :src="require('@/assets/img/qr-codes/android-google-icon.webp')"/>
                    </div>
                    <p class="p2 mt-2" v-tr>1. Open the Google App|1. Ouvrez l'application Google</p>
                </b-col>
                <b-col cols="4" class="text-center">
                    <b-img height="180" :src="require('@/assets/img/qr-codes/google-app-photo.webp')"/>

                    <p class="p2 mt-2" v-tr>2. Tap the camera icon at the right of the search bar|2. Appuyez sur l'icône de caméra à droite de la barre de recherche</p>
                </b-col>
                <b-col cols="4" class="text-center">
                    <b-img width="300" class="mb-3" :src="require('@/assets/img/qr-codes/google-app-search.webp')"/>

                    <p class="p2 mt-2" v-tr>3. Tap "Search with your camera" zone|3. Appuyez sur la zone "Effectuez une recherche avec votre appareil photo</p>
                </b-col>
            </b-row>
            <b-row class="mt-2">
                <b-col cols="4" class="text-center ml-auto">
                    <b-img height="500" :src="require('@/assets/img/qr-codes/qr-scan-hand.webp')"/>

                    <p class="p2 mt-2" v-tr>4. Place the phone to make the code visible on the screen|4. Placez le téléphone de manière à ce que le code apparaisse à l’écran</p>
                </b-col>
                <b-col cols="4" class="text-center mr-auto">
                    <qr-code-with-overlay class="mx-auto" height="500" center :url="qrCodeUrl" :overlay-url="require('@/assets/img/qr-codes/android-qrcode-overlay-tuto.webp')" />
                    <p class="p2 mt-2" v-tr>5. Tap the "Website" white button that will appear at the bottom of the screen|5. Appuyez sur le bouton blanc "Site internet" qui s'affichera au bas de l'écran</p>
                </b-col>
            </b-row>
        </template>

        <template #modal-footer>
            <b-btn variant="" @click="visibleComputed = false">
                <span v-tr>Close|Fermer</span>
            </b-btn>
        </template>
    </b-modal>
</template>

<script>
import QrCodeWithOverlay from "@/components/qr-code-with-overlay.vue";
import VirtualKeyboard from "@/components/virtual-keyboard.vue";
import VirtualKeypad from "@/components/virtual-keypad.vue";
import EQrCode from "../../../vue-components/components/e-qr-code.vue";

import countryPhones from "@/../vue-components/nuxt/components/ui/country-phones.json"
import NetworkV2 from "../../../vue-components/helpers/NetworkV2.js";
import Validation from "../../../vue-components/helpers/Validation.js";

// modal-send-to-phone
export default {
    name: `modal-send-to-phone`,
    components: {EQrCode, VirtualKeyboard, VirtualKeypad, QrCodeWithOverlay},
    props: {
        qrCodeUrl: {type: String},
        visible: {type: Boolean},
        navPath: { type: Object },
    },
    data() {
        return {
            extension: `+1`,
            screen: `home`,// home|
            device: `iphone`,// iphone|android
            ext: ``,
            phone: ``,
            countryPhones
        }
    },
    computed: {
        visibleComputed: {
            get() {
                return this.visible;
            },
            set(value) {
                this.$emit(`update:visible`, value);
            }
        },
        isPhoneValid() {
            return Validation.testPhoneNumber(this.extension + this.phone, true);
        }
    },
    methods: {
        onKeyboardChange(input) {
            if (input && input.length > 10) {
                input = input.substr(0, 10);
            }
            this.phone = input;
        },
        setScreen(screen) {
            this.screen = screen;
        },
        close() {
            this.setScreen(`home`);
            this.visibleComputed = false;
        },
        onHidden() {
            this.setScreen(`home`);
        },
        shareSMS() {
            this.showLoading();
            NetworkV2.post(`/api/public/share/nav-path/sms`, {
                language: this.language,
                destination: this.navPath.end.getName(),
                phone: this.phone,
                extension: this.extension,
                url: this.qrCodeUrl
            })
                .then(_ => {
                    this.close();
                    this.setScreen(`home`);
                    this.phone = ``;
                    this.showSavedMessage(`SMS Sent|SMS envoyé`, `Please check your SMS and open the link|Merci de vérifier vos messages et ouvrez le lien`);
                })
                .catch(e => {
                    console.error(e);
                    this.showErrorMessage(`Invalid Number|Numéro non valide`, `Please check your phone number and try again|Merci de vérifier votre numéro de téléphone et de ré-essayer`);
                });
        }
        // formatPhone(phone) {
        //     // let value = `(___) ___ ____`;
        //
        //     let value = phone.replace(/\D/g, ``).match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
        //     value = !value[2] ? `(` + value[1] : `(` + value[1] + `) ` + value[2] + (value[3] ? `-` + value[3] : ``);
        //
        //     value = value + `(___) ___ ____`.substring(value.length);
        //
        //     return value;
        // }
    }
}
</script>

<style lang="scss">
.gradient-card {
    .card-body {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        border: 2px solid var(--accent-color);
        background: linear-gradient(180deg, #ffffff, #e3f3f2);
        border-radius: inherit
    }
}
</style>
