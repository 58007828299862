import Model, {type} from "../../abstract/Model.js";
import DateCreationEditionMixin from "../../mixins/DateCreationEditionMixin.js";

/**
 * List of all routes, useful for updating the route status when finished
 * @class
 * @extends AbstractModel
 * @augments DateCreationEditionMixin
 */
export default class CmsAnalyticsRoute extends DateCreationEditionMixin(Model) {
    static collection = `cms_analytics_routes`;

    static indexes = [{"start.id": 1}, {"destination.id": 1}];

    dealerid = {
        [type]: String,
        flags: [F_LOWER],
        required: true,
        index: 1
    }

    websiteId = {
        [type]: String,
        required: true,
        index: 1
    }

    sessionId = {
        [type]: String,
        required: true,
        index: 1
    }

    routeId = {
        [type]: String,
        required: true,
        index: 1
    }

    start = {
        [type]: Object,
        required: true
    }

    destination = {
        [type]: Object,
        required: true
    }

    started = {
        [type]: Boolean,
        default: false
    }

    finished = {
        [type]: Boolean,
        default: false
    }

    navPoints = {
        [type]: Array,
        required: true,
        default: []
    }

    /**
     * Path distance in Meters
     * @type {{}}
     */
    distance = {
        [type]: Number
    }

    /**
     * Walk duration estimated in Minutes
     * @type {{}}
     */
    duration = {
        [type]: Number
    }

    rating = {
        [type]: Number
    }

    feedback = {
        [type]: String
    }

    constructor() {
        super(...arguments);
    }

    updateRouteId() {
        if (this.start && this.destination) {
            this.routeId = `${this.start.id}-${this.destination.id}`;
        } else {
            console.error(`missing start ${this.start} or destination ${this.destination}`);
        }
    }
}
